import { Helmet } from "react-helmet-async"

import ProductsHero from "../components/Products/ProductsHero"
import HraContact from "../components/Hra/HraContact"

import guideGchra from "../assets/guide-gchra.svg"

export default function Gchra() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "Product",
		"name": "Group Coverage Health Reimbursement Arrangement (GCHRA)",
		"description": "Salusion’s GCHRA administration software makes it easy for employers to help employees cover out-of-pocket expenses not fully paid by their group health plan.",
		"brand": {
			"@type": "Brand",
			"name": "Salusion"
		},
		"offers": {
			"@type": "Offer",
			"price": "5.00 / participant / month",
			"priceCurrency": "USD",
			"availability": "https://schema.org/InStock",
			"priceSpecification": {
				"@type": "UnitPriceSpecification",
				"price": "5.00",
				"priceCurrency": "USD",
				"billingDuration": 1,
				"billingIncrement": 1,
				"billingPeriod": "http://purl.org/goodrelations/v1#Month",
				"unitText": "participant/month",
			},
			"eligibleQuantity": {
				"@type": "QuantitativeValue",
				"value": 1,
				"unitText": "participant",
			},
			"shippingDetails": {
				"@type": "OfferShippingDetails",
				"shippingRate": {
					"@type": "MonetaryAmount",
					"value": "0.00",
					"currency": "USD"
				},
				"shippingDestination": {
					"@type": "DefinedRegion",
					"name": "United States"
				},
				"deliveryTime": {
					"@type": "QuantitativeValue",
					"value": "0",
					"unitCode": "DAY"
				}
			},
		},
		"image": "https://salusion.com/assets/images/homepage/reimbursement.svg",
		"url": "https://www.salusion.com/product/gchra",
		"datePublished": "2024-10-05T14:30:00+00:00",
		"dateModified": "2024-10-05T14:30:00+00:00",

	}
	return (
		<>
			<Helmet>
				<title>Salusion - Gchra</title>
				<meta name="title" content="Simple and Affordable GCHRA Administration | Salusion" />
				<meta name="description" content="Salusion’s GCHRA administration software helps employees cover out-of-pocket expenses not fully paid by their group health plan, making health benefit management easy and cost-effective for employers." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>
			<ProductsHero
				title="Simple and Affordable GCHRA Administration | Salusion"
				subtitle="Salusion’s GCHRA administration software makes it easy for employers to help employees cover out-of-pocket expenses not fully paid by their group health plan."
				poster={guideGchra}
				video="https://www.youtube.com/embed/dNix0isqGfw?si=zO81hNyHZGOkrluN"
			/>
			<div className="hra-padding-top">
				<HraContact />
			</div>
		</>
	)
}

import Accordion from "react-bootstrap/Accordion"

export default function LearningCenter() {
	const styles = {
		container: {
			margin: "100px auto"
		}
	}

	return (
		<>
			<section>
				<div id="referral" className="container" style={styles.container}>
					<div className="white-wrapper-all">
						<div className="guide-accordion-all">
							<Accordion>
								<Accordion.Item eventKey="0">
									<Accordion.Header>Getting Started</Accordion.Header>

									<Accordion.Body>
										<p>
											If you already have a Salusion account, log in and go to the referral section. If you don’t have
											an account yet, click the “Create Referral Account” button above to set up your login and
											password.
										</p>
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="1">
									<Accordion.Header>How It Works</Accordion.Header>

									<Accordion.Body>
										<p>
											Salusion pays program members $42 per participant for ICHRAs and QSEHRAs, and $15 per participant
											for EBHRAs and GCHRAs. A participant is any employee at the referral company who generates three
											months of fees within the first three months of their initial plan year. Payments will be wired to
											the referrer's account in the third month of the company's initial plan year.
										</p>

										<p>
											Referrals must be submitted before the referred company creates an account with Salusion, and only
											one referral is allowed per company.
										</p>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

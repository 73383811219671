import { Accordion } from "react-bootstrap"

export default function LargeBusinessAccordion() {
	return (
		<>
			<Accordion>
				<Accordion.Item eventKey="0">
					<Accordion.Header>Applicable Large Employers</Accordion.Header>
					<Accordion.Body>
						<p>
							<b>Determining ALE Status</b>
							<br />
							An employer’s status as an Applicable Large Employer (ALE) is determined annually based on the average
							size of the workforce during the previous calendar year. If an employer has at least 50 full-time
							employees, including full-time equivalent employees, on average during the prior year, they are considered
							an ALE for the current calendar year. As an ALE, the employer is subject to the employer shared
							responsibility provisions and the employer information reporting requirements (discussed below).
						</p>
						<p>
							<b>Calculating Workforce Size</b>
							<br />
							To determine workforce size for a year, employers should follow these steps:
						</p>
						<ol>
							<li>
								<b>Add Full-Time Employees:</b> Calculate the total number of full-time employees for each month of the
								prior calendar year.
							</li>
							<li>
								<b>Add Full-Time Equivalent Employees:</b> Calculate the total number of full-time equivalent employees
								for each month of the prior calendar year.
							</li>
							<li>
								<b>Calculate the Average:</b> Add the totals from steps 1 and 2, then divide the sum by 12.
							</li>
						</ol>
						<p>For this calclulation:</p>
						<ul>
							<li>
								A full-time employee is defined as someone who works at least 30 hours per week or 130 hours per month.
							</li>
							<li>
								A full-time equivalent employee is a combination of employees who are not full-time individually but
								together equal one full-time employee. To determine the number of full-time equivalent employees for a
								month:
								<ul>
									<li>
										Combine the total number of hours worked by all non-full-time employees, but do not count more than
										120 hours per employee.
									</li>
									<li>Divide the total by 120. </li>
								</ul>
							</li>
						</ul>
						<p>Full-time equivalent employees are relevant only for determining ALE status.</p>
						<p>For assistance with this calculation, please refer to the attached Excel file</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="1">
					<Accordion.Header>ICHRAs and the ACA</Accordion.Header>
					<Accordion.Body>
						<p>
							The Affordable Care Act (ACA) includes provisions within the U.S. Internal Revenue Code that address
							employer-sponsored health insurance. Specifically, Code §4980H outlines employer shared responsibility
							requirements, which mandate that ALEs offer affordable health coverage to their full-time employees and
							dependents. ALEs that fail to comply may face penalties.
						</p>
						<p>There are two key subsections under Code §4980H:</p>
						<ul>
							<li>
								<b>§4980H(a)</b>: Imposes a penalty on ALEs that fail to offer minimum essential coverage to at least
								95% of full-time employees and their dependents if at least one full-time employee receives a premium
								tax credit for purchasing coverage through a Health Insurance Marketplace.
							</li>
							<li>
								<b>§4980H(b)</b>: Imposes a penalty on ALEs that offer coverage that is either unaffordable or does not
								provide minimum value. The penalty applies if at least one full-time employee receives a premium tax
								credit for purchasing coverage through a Marketplace.{" "}
							</li>
						</ul>
						<p>
							<b>§4980H(a)</b>
							<br />
							By offering an ICHRA to an employee, an ALE is considered to provide minimum essential coverage under
							§4980H(a).
						</p>
						<p>
							<b>Affordability Under §4980H(b)</b>
							<br />
							For purposes of §4980H(b), ICHRA coverage is considered to provide minimum value if it is deemed
							affordable. An ICHRA is affordable if the self-only amount made available to the employee for a month
							exceeds the difference between:
						</p>
						<ol type="a">
							<li>
								The monthly self-only premium for the lowest-cost silver plan offered by the Exchange in the employee’s
								rating area, and
							</li>
							<li>8.39% of the employee’s household income.</li>
						</ol>
						<p>The value of the lowest-cost silver plan is age dependent.  The determination of the employee's age is made on the first day of the plan year (or the first day of ICHRA coverage eligibility, if later).</p>
						<p>
							<b>Safe Harbors</b>
							<br />
							Salusion uses the Plan Year Safe Harbor when determining the value of the lowest-cost silver plan. To simplify and avoid adjusting allowances for individual incomes, Salusion uses the Household Income Safe Harbor, assuming every employee earns at least the federal poverty level (FPL). This results in the following affordability expression:
						</p>
						<ul>
							<li>
								<b>Location Safe Harbor</b>: ALEs may use the silver plan premium for an employee’s primary worksite
								rather than their residence. The primary worksite is where the employer reasonably expects the employee
								to work at the start of the plan year. For remote workers, the residence is typically the primary
								worksite unless otherwise specified.
							</li>
							<li>
								<b>Household Income Safe Harbors</b>: ALEs may use the employee’s W-2 wages, rate of pay, or the federal
								poverty line to estimate household income for affordability purposes.
							</li>
							<li>
								<b>Plan Year Safe Harbor</b>: ALEs may use the premium for the lowest-cost silver plan for January of
								the prior calendar year for a calendar-year plan. For non-calendar-year plans, the premium for January
								of the current year may be used.
							</li>
						</ul>
						<p>ALEs must apply these safe harbors uniformly and consistently across all employees in a class.</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="2">
					<Accordion.Header>Creating ACA-Compliant ICHRAs with Salusion</Accordion.Header>
					<Accordion.Body>
						<p>
							Employers often seek to create cost-effective ICHRAs that comply with the ACA. At Salusion, we create a
							class of full-time employees for each U.S. county, assigning employees to classes based on their zip
							codes. Allowances within each class vary by age, as marketplace health premiums increase with age in every
							state except New York and Vermont.
						</p>
						<p>An ICHRA is considered affordable if the allowance exceeds the difference between:</p>
						<ol type="a">
							<li>The lowest-cost silver plan for the employee, based on their age, and</li>
							<li>8.39% of the employee’s household income.</li>
						</ol>

						<p>
							To simplify and avoid adjusting allowances for individual incomes, Salusion uses a safe harbor, assuming
							every employee earns at least the federal poverty level (FPL). This results in the following affordability
							expression:
						</p>
						<p>
							<b>
								LCS<sub>Employee’s Age</sub> - FPL × 8.39%
							</b>
						</p>
						<p>
							Since multiple employees may belong to a class, the calculation is adjusted to ensure sufficient
							allowances for all ages. The allowance is never less than one-third of the affordable amount for a
							64-year-old, leading to the final expression:
						</p>
						<p>
							<b>
								Max(LCS<sub>Employee’s Age</sub> - FPL × 8.39%, (LCS64y - FPL × 8.39%) / 3)
							</b>
						</p>
						<p>Employers can use this formula to calculate their ACA-compliant ICHRA.</p>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>
	)
}

import { Link } from "react-router-dom"
import Lottie from "lottie-react"
import { toSalusionPrivate } from "../../util"
import animationHero from "../../lottie/salutions-hero.json"

export default function Hero() {
	return (
		<section className="first-section first-padding" id="hero">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-12 col-md-7">
						<div className="hero-content">
							<h1>Support Better Benefits</h1>
							<h2 style={{ color: "#7fc2ff" }}>Get Rewarded</h2>

							<p>
								Join Salusion's Referral Program and help companies enhance their employee benefits with our
								cost-effective HRAS. Refer a company and earn up to $42 per participant. Learn more below.
							</p>

							<Link
								className="btn-linear"
								onClick={(event) => toSalusionPrivate(event, "/create-referral-account", false)}
							>
								Create A Referral Account
							</Link>
						</div>
					</div>
					<div className="col-12 col-md-5">
						<div className="hero-animation">
							<Lottie animationData={animationHero} loop={false} />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

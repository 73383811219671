import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import ichravsqshera from "../../../assets/ichravsqsehra-poster.svg"
import ichraRules from "../../../assets/ichraRules.svg"
import employees from "../../../assets/50-employees.svg"
import ichraCalculator from "../../../assets/ichra-calculator.svg"
import reimbursement from "../../../assets/reimbursement-options.svg"
import guideIchra from "../../../assets/guide-to-ichra.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What is the definition of a seasonal employee for an ICHRA and a QSEHRA?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What is the definition of a seasonal employee for an ICHRA and a QSEHRA?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Seasonal employees are defined under the Code §4980H or the Code §105 regulations, as elected by the HRA sponsor.",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}
	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What is the definition of a seasonal employee for an ICHRA and a QSEHRA?" />
				<meta name="description" content="Seasonal employees are defined under the Code §4980H or the Code §105 regulations, as elected by the HRA sponsor." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							Seasonal employees are defined under the Code §4980H or the Code §105 regulations, as elected by the HRA
							sponsor.
						</p>

						<ul>
							<li>
								Using Code §4980H, a seasonal employee performs labor or services on a seasonal basis as defined by the
								Secretary of Labor, including workers covered by section 500.20(s)(1) of title 29, Code of Federal
								Regulations and retail workers employed exclusively during holiday seasons
							</li>

							<li>
								Using Code §105, employees whose customary annual employment is less than 9 months, if other employees
								in similar work with the same employer (or, if no employees of the employer are in similar work, in
								similar work in the same industry and location) have substantially more months.
							</li>
						</ul>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra/video-guide"
				/>
				<ResourceCenterVideo
					resourceImg={ichraRules}
					title="Take a deep dive into the rules and regulations that govern ICHRAs"
					pageLink="/product/ichra/rules-and-regulations"
				/>

				<ResourceCenterVideo
					resourceImg={ichravsqshera}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={employees}
					title="ICHRA topics for large employers"
					pageLink="/product/ichra/large-business"
				/>

				<ResourceCenterVideo
					resourceImg={ichraCalculator}
					title="Discover how much you can save with an ACA-compliant ICHRA"
					e
					xternalLink="product/ichra/aca-compliant-calculator"
				/>

				<ResourceCenterVideo
					resourceImg={reimbursement}
					title="Discussion of the pros and cons of each reimbursement option"
					pageLink="/product/ichra/reimbursement-options"
				/>
			</ResourceCenter>
		</>
	)
}
